import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { removing } from "../../../redux/slices/orderSlice";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { pink } from '@mui/material/colors';
import './style.scss';

import myIcon from '../../../img/checkout/trash.svg';


const Checkout = () => {
    const dispatch = useAppDispatch();

    const { selected, goods, isHight } = useAppSelector((state: RootState) => state.orderSlice);
    
    const getItems = goods
    .filter((item) => selected.includes(item.categoryId))
    .sort((a, b) => selected.indexOf(a.categoryId) - selected.indexOf(b.categoryId))
    .map((item) => {
        if(item.count>0) {
            return (
                <div className="checkout_item" key={item.categoryId}>
                    <div className="checkout_item_wrapper">
                        <div className="checkout_item_color" style={{background: `${item.pathColor}`}}></div>
                        <div className="checkout_item_left">
                            <span className="checkout_item_title">{item.title}</span>
                            <div className="checkout_item_left_price-info">
                                <span className="checkout_item_count">{item.count} x </span>
                                <span className="checkout_item_price">{((Number(item.prices.standart))).toFixed(2)} Br</span>
                            </div>
                        </div>
                        <div className="checkout_item_prices">
                            <span className="checkout_item_prices_current">{((item.count * Number(item.prices.standart))).toFixed(2)} Br</span>
                        </div>
                        <IconButton className="checkout_item_remove" size="small" onClick={() => dispatch(removing(item.categoryId))}>
                            <img src={myIcon} alt="My icon" />
                        </IconButton>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    });
      
    return (
        <div className="checkout order-mode_checkout">
            <div className="checkout_container">
                <div className="checkout_wrapper">
                    {getItems}
                    {isHight && (
                        <div className="checkout_warning">
                            <WarningAmberRoundedIcon fontSize="small" sx={{ color: pink[500] }}/>
                            <span className="checkout_warning_text">Некоторые билеты из Вашего заказа были распроданы.</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Checkout;