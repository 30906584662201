import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Localized from "../../../Localized";
import { changeStep } from "../../../redux/slices/windowSlice";
import { selectPaymentMethod } from "../../../redux/slices/orderSlice";
//selectPaymentMethod

import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";

import "./style.scss";

import iconButton from "../../../img/header/arrow-left.svg";
import Timer from "./timer";
import { IconButton } from "@mui/material";
import { useEffect } from "react";

const Header = () => {
    const dispatch = useAppDispatch();
    const { stepCurrent, isTestMode, headerCutsom } = useAppSelector(
        (state: RootState) => state.windowSlice
    );
    const { selectedPaymentMethod } = useAppSelector(
        (state: RootState) => state.orderSlice
    );

    useEffect(() => {
        if (
            selectedPaymentMethod != null &&
            (stepCurrent == 1 || stepCurrent == 2)
        ) {
            dispatch(selectPaymentMethod(null));
        }
    }, [stepCurrent]);

    const getBack = () => {
        switch (stepCurrent) {
            case 1:
                return (
                    <IconButton
                        className="header_back"
                        size="small"
                        onClick={() => dispatch(changeStep(-1))}>
                        <img src={iconButton} alt="back" />
                    </IconButton>
                );
                break;
            case 2:
            case 3:
                return (
                    <IconButton
                        className="header_back"
                        size="small"
                        onClick={() => dispatch(changeStep(-1))}>
                        <img src={iconButton} alt="back" />
                    </IconButton>
                );
        }
    };

    const getTitle = () => {
        if(headerCutsom) {
            return headerCutsom;
        }
        switch (stepCurrent) {
            case -1:
                return "Пожалуйста, подождите...";
            case 0:
                return Localized.HeaderSelectTicket;
            case 1:
                return Localized.HeaderYourOrder;
            case 2:
            case 3:
                return "Способ оплаты";
            case 4:
                return "Оплата";
            case 5:
                return Localized.HeaderFinal;
            default:
                return "Ошибка";
        }
    };

    return (
        <header className="header">
            <div className="header_container">
                <div className="header_wrapper">
                    {getBack()}
                    <div className="header_center">
                        <h1 className="header_title">{getTitle()}</h1>
                        <Timer />
                    </div>
                </div>
            </div>
            {isTestMode ? (
                <div className="header_testmode">
                    <span className="header_testmode_text">Тестовый режим</span>
                </div>
            ) : (null)}
        </header>
    );
};

export default Header;
