import './style.scss';
import { useCloseSessionMutation } from "../../redux/api";


const SessionTimeoutDialog = ({  open, onContinue }) => {

    const [ closeSession ] = useCloseSessionMutation();

    return (
      <div className="close-session" style={open ? {opacity: 1, pointerEvents: 'all'} : {}}>
          <span className="close-session_text">Желаете продолжить оформление заказа?</span>
          <div className="close-session_btns">
              <div className="close-session_cancel" onClick={() => closeSession()}>Отменить</div>
              <div className="close-session_close" onClick={()=>onContinue()}>Продолжить</div>
          </div>
      </div>
    );
}

export default SessionTimeoutDialog;