import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { api } from '../redux/api';

import orderSlice from '../redux/slices/orderSlice';
import userSlice from '../redux/slices/userSlice';
import windowSlice from '../redux/slices/windowSlice';

export const store = configureStore({
  reducer: {

    orderSlice: orderSlice,
    userSlice: userSlice,
    windowSlice: windowSlice,
    
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
