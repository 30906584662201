import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from '../api';
import { UserInfo } from '../types/user';

const initialState: UserInfo = {
  token: '',
  mail: '',
  isMailValid: false,
  expirationTime: null,
  isWaiting: false,
};


const userInfo = createSlice({
  name: 'user',
  initialState,
  reducers: {

    setMail(state, action: PayloadAction<string>) {
        const re = /\S+@\S+\.\S+/;
        if(re.test(action.payload)) {
          state.isMailValid = true;
        } else {
          state.isMailValid = false;
        }
        state.mail = action.payload;
    },

    setExpirationTime(state, action: PayloadAction) {
      state.expirationTime = action.payload['expirationTime'];
    },

  },
  extraReducers: (builder) => {
    builder
    .addMatcher(
      api.endpoints.closeSession.matchFulfilled,
      (state, { payload }) => {
        state.expirationTime = null;
      }
    )
    .addMatcher(
      api.endpoints.getToken.matchFulfilled,
      (state, { payload }) => {
        state.token = payload['token']; 
        state.isWaiting = payload['state'] === "waiting";
      }
    )
    
    .addMatcher(
      api.endpoints.closeSession.matchFulfilled,
        (state, { payload }) => {
          if(payload['response'] === 'ok') {
            window.location.replace(payload['link']);
          }
        }
    )
    .addMatcher(
      api.endpoints.checkToken.matchFulfilled,
      (state, { payload }) => {
        if (payload['state'] === "active") {
          state.isWaiting = false;
        }
      }
    )
    .addMatcher(
      api.endpoints.updateProducts.matchFulfilled,
        (state, { payload }) => {
          if(payload['response'] === 'ok' && payload['expiration_time']) {
            state.expirationTime = new Date(payload.expiration_time).toISOString();
          }
        }
    )
    .addMatcher(
      api.endpoints.checkToken.matchRejected,
      (state, action) => {
        state.expirationTime = null;
      }
    )
    .addMatcher(
      api.endpoints.updateProducts.matchRejected,
      (state, action) => {
        state.expirationTime = null;
      }
    )
    .addMatcher(
      api.endpoints.confirmProducts.matchRejected,
      (state, action) => {
        state.expirationTime = null;
      }
    )
    .addMatcher(
      api.endpoints.setPaymentMethod.matchRejected,
      (state, action) => {
        state.expirationTime = null;
      }
    )
    .addMatcher(
      api.endpoints.setEmail.matchRejected,
      (state, action) => {
        

        if (action.payload) { 
          switch (action.payload.status) {
            case 400:
              break;
            case 429:
            case 503:
              state.expirationTime = null;
              break;
              
            default:
              state.expirationTime = null;
              break;
          }
        } else if (action.error) { 
          state.expirationTime = null;
        }
      }
    )
  },
});

export const { setMail, setExpirationTime } = userInfo.actions;
export default userInfo.reducer;