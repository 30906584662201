import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { sha256 } from "js-sha256";

import Header from "../Window/Header";
import Main from "../Steps/Main";
import Footer from "../Window/Footer";
import Checkout from "../Steps/Checkout";
import Loader from "../Loader";
import SessionTimeout from "../TimerCloseSession/SessionTimeout";
import EmailModal from "../Steps/EmailModal";
import PaymentStep from "../Steps/PaymentStep";
import PaymentMethods from "../Steps/PaymentMethods";
import WaitingStep from "../Steps/WaitingStep";
import CloseSession from "../Steps/CloseSession";

import {
    useGetTokenMutation,
    useGetProductsQuery,
    useCheckTokenMutation,
    useGetMaxCountProductsQuery,
} from "../../redux/api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { isMainLoader } from "../../redux/slices/windowSlice";
import { setEventId } from "../../redux/slices/orderSlice";

import "./style.scss";
import { RootState } from "../../app/store";

const App = () => {
    const dispatch = useAppDispatch();
    const { stepCurrent, isWaiting, token, expirationTime } = useAppSelector(
        (state: RootState) => ({ ...state.windowSlice, ...state.userSlice })
    );

    const [searchParams] = useSearchParams();
    const [getToken, { isSuccess: isTokenSuccess, isError: isTokenError }] =
        useGetTokenMutation();
    const [checkToken] = useCheckTokenMutation();
    const [shouldFetchProducts, setShouldFetchProducts] = useState(false);
    const [timestamp, setTimestamp] = useState(Date.now());

    useEffect(() => {
        const eventId = searchParams.get('event_id');
        if (eventId) {
          dispatch(setEventId(eventId));
        }
      }, [searchParams, dispatch]);

    useEffect(() => {
        const fingerprint = sha256(
            [
                navigator.language,
                JSON.stringify(navigator.languages),
                window.screen.width,
                window.screen.height,
                navigator.userAgent,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
                navigator.hardwareConcurrency || "unknown",
                navigator.maxTouchPoints || "unknown",
                window.screen.colorDepth,
                window.screen.pixelDepth,
            ].join("-")
        );

        getToken({
            event_id: Number(searchParams.get("event_id")),
            fingerprint,
        });
    }, [getToken, searchParams]);

    useEffect(() => {
        if (isTokenSuccess) {
            setShouldFetchProducts(true);
        }
    }, [isTokenSuccess]);

    useEffect(() => {
        if (isTokenError) {
            dispatch(isMainLoader(false));
        }
    }, [isTokenError]);

    // Используем параметры запроса условно, основываясь на успешности получения токена
    const {
        data: tempProducts,
        isLoading,
        isError,
    } = useGetProductsQuery(
        {
            product_id: Number(searchParams.get("ticket_id")),
            event_id: Number(searchParams.get("event_id")),
        },
        {
            skip: !shouldFetchProducts,
        }
    );

    useEffect(() => {
        let tokenIntervalId;
        const checkInterval = isWaiting ? 15000 : 30000;

        if ((isWaiting || token) && [0, 1, 2, 3].includes(stepCurrent)) {
            tokenIntervalId = setInterval(() => {
                checkToken();
            }, checkInterval);
        }

        return () => clearInterval(tokenIntervalId);
    }, [isWaiting, token, checkToken, stepCurrent]);

    useEffect(() => {
        let intervalId;
    
        if (expirationTime === null) {
          // Устанавливаем timestamp в текущее время для начального запроса
          setTimestamp(Date.now());
    
          // Затем обновляем timestamp каждые 30 секунд, чтобы вызвать перезапрос
          intervalId = setInterval(() => {
            setTimestamp(Date.now());
          }, 30000);
        }
    
        return () => {
          if (intervalId) {
            clearInterval(intervalId);
          }
        };
      }, [expirationTime]);
    
      const { data: tempNewProducts } = useGetMaxCountProductsQuery({
        event_id: Number(searchParams.get("event_id")),
        _timestamp: timestamp // Добавляем timestamp к параметрам запроса
      }, {
        skip: !shouldFetchProducts || expirationTime !== null || stepCurrent !== 0, // Пропускаем запрос, если не должны его выполнять
      });

    // useEffect(() => {
    //     if (token && !isWaiting) {
    //         getProducts(searchParams.get("ticket_id"));
    //     }
    // }, [isWaiting, token, getProducts, searchParams]);

    // Dispatch isMainLoader action based on the presence of tempProducts
    useEffect(() => {
        dispatch(isMainLoader(!tempProducts));
    }, [tempProducts, dispatch]);

    const getWindowClassName = () => {
        const baseClass = "order-mode";
        const stepClasses = [
            "",
            "checkout-step",
            "contact-step",
            "contact-step",
            "payment",
        ];
        return `${baseClass} ${stepClasses[stepCurrent] || ""}`;
    };

    return (
        <div className="modal">
            <Loader />
            <WaitingStep />
            <SessionTimeout />
            <Header />
            <EmailModal />
            <CloseSession />
            <div className={getWindowClassName()}>
                <Main />
                <Checkout />
                <PaymentMethods />
                <PaymentStep />
            </div>
            <Footer />
        </div>
    );
};

export default App;
