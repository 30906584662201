import LocalizedStrings from 'react-localization';

let Localized = new LocalizedStrings({
    ru:{
      HeaderSelectTicket:"Выбор билетов",
      HeaderYourOrder:"Мои билеты",
      HeaderContact:"Контактные данные",
      HeaderFinal:"Завершение",

      ItemAvailable:"Доступно",

      BtnAddToCartBtn:"Добавить",
      BtnUnavailableBtn:"Распродано",
      BtnLookOrder:"Добавить в корзину",
      BtnPlaceOrder:"Продолжить",
      BtnPayOrder:"Перейти к оплате",
      BtnOrderEmpty:"Корзина пуста",

      LabelSpecialPriceText:"Спец. цена по карте Visa от Альфа Банка",
      SpanSpecialPaymentMethod: "Оплата только картой Visa от Альфа Банка",

      SpanContactMailtext: "Приобретенные Вами билеты будут отправлены на электронный адрес:",
      SpanContactMailplaceholder: "E-mail",

      CheckpointCorrect: "Пожалуйста, убедитесь, что адрес электронной почты введен правильно.",
      CheckpointAgree: "Я согласен с правилами пользования сервисом.",

      SummaryTicket: "билет",
      Summary2Tickets: "билета",
      SummaryTickets: "билетов",
      SummarySum: "на сумму",
      SummaryService: "Работа сервиса",
      SummaryTotal: "Всего",

      FinalThankYou: "Спасибо за покупку!",
      FinalText: 'Билеты и подтверждение заказа будут отправлены на адрес электронной почты {email}. Если письмо не поступит в течение 15 минут, проверьте папку "Спам". Если ситуация не разрешится – свяжитесь с нами.',
      FinalDownloadTicket: "Скачать PDF билет",
      FinalDownloadInvoice: "Скачать счёт",

      SelectedText: "Выбрано",
      YouCanAddText: "Вы можете добавить к своему заказу",
      TimeLeft: "на покупку билетов"
    },
    pl:{
      HeaderSelectTicket:"Wybierz bilety",
      HeaderYourOrder:"Podsumowanie",
      HeaderContact:"Dane kontaktowe",
      HeaderFinal:"Koniec",

      ItemAvailable:"Dostępne",

      BtnAddToCartBtn:"Do koszyka",
      BtnUnavailableBtn:"Wyprzedane",
      BtnLookOrder:"Przejdź do podsumowania",
      BtnPlaceOrder:"Przejdź do potwierdzenia",
      BtnPayOrder:"Przejdź do płatności",
      BtnOrderEmpty:"Koszyk jest pusty",

      LabelSpecialPriceText:"Znizka przy opłacie kartą Visa od Alfa Banku.",
      SpanSpecialPaymentMethod: "Opłata zamówienia tylko kartą Visa od Alfa Banku",

      SpanContactMailtext: "Po opłacie bilety zostaną wysłane na dany adres mailowy:",
      SpanContactMailplaceholder: "E-mail",

      CheckpointCorrect: "Potwierdzam prawidłowość wpisanych przeze mnie danych.",
      CheckpointAgree: "Zgadzam się z regułami korzystania serwisu.",

      SummaryTicket: "bilet", //cena 3 biletow
      Summary2Tickets: "bileta",
      SummaryTickets: "biletów",
      SummarySum: "na kwotę",
      SummaryService: "Opłata serwisowa",
      SummaryTotal: "Razem",

      FinalThankYou: "Dziękujemy za zakup!",
      FinalText: "Bilety i potwierdzenie zamówienia zostaną wysłane na adres e-mail {email}. Możesz je również pobrać w tym oknie.",
      FinalDownloadTicket: "Pobierz bilet PDF",
      FinalDownloadInvoice: "Pobierz fakturę",

      SelectedText: "Wybrano:",
      YouCanAddText: "Możesz dodać do swojego zamówienia:",
      TimeLeft: "Pozostało czasu:"
    },
    en:{
      HeaderSelectTicket:"Ticket selection",
      HeaderYourOrder:"Your order",
      HeaderContact:"Contact details",
      HeaderFinal:"Final",

      ItemAvailable:"Available",

      BtnAddToCartBtn:"Add to Cart",
      BtnUnavailableBtn:"Sold out",
      BtnLookOrder:"View the order",
      BtnPlaceOrder:"Place an order",
      BtnPayOrder:"Pay for the order",
      BtnOrderEmpty:"A cart is empty",

      LabelSpecialPriceText:"Special price on Visa card from Alfa Bank",
      SpanSpecialPaymentMethod: "Payment only by Visa card from Alfa Bank",

      SpanContactMailtext: "The tickets you have purchased will be sent to the email address:",
      SpanContactMailplaceholder: "Email",

      CheckpointCorrect: "I confirm the correctness of the data I entered.",
      CheckpointAgree: "I agree with the terms of use of the service.",

      SummaryTicket: "ticket",
      Summary2Tickets: "tickets",
      SummaryTickets: "tickets",
      SummarySum: "for the amount of",
      SummaryService: "Service fee",
      SummaryTotal: "Order amount",

      FinalThankYou: "Thank you for your purchase!",
      FinalText: "Tickets and order confirmation will be sent to the email address {email}. You can also download them in this window.",
      FinalDownloadTicket: "Download PDF ticket",
      FinalDownloadInvoice: "Download the invoice",

      SelectedText: "Selected:",
      YouCanAddText: "You can add to your order:",
      TimeLeft: "Time left:"
    },
    be:{
      HeaderSelectTicket:"Выбар квіткоў",
      HeaderYourOrder:"Ваша замова",
      HeaderContact:"Кантактныя дадзеныя",
      HeaderFinal:"Канец",

      ItemAvailable:"Даступна",

      BtnAddToCartBtn:"У кошык",
      BtnUnavailableBtn:"Распрададзены",
      BtnLookOrder:"Паглядзець заказ",
      BtnPlaceOrder:"Аформіць заказ",
      BtnPayOrder:"Аплаціць заказ",
      BtnOrderEmpty:"Кошык пусты",

      LabelSpecialPriceText:"Спец. цана па карце Visa ад Альфа Банка",
      SpanSpecialPaymentMethod: "Аплата толькі картай Visa ад Альфа Банка",

      SpanContactMailtext: "Набытыя Вамі квіткі будуць адпраўленыя на электронны адрас:",
      SpanContactMailplaceholder: "Email",

      CheckpointCorrect: "Пацвярджаю правільнасць уведзеных мною дадзеных.",
      CheckpointAgree: "Я згодны з правіламі карыстання сэрвісам.",

      SummaryTicket: "квіток",
      Summary2Tickets: "квіткі",
      SummaryTickets: "квіткоў",
      SummarySum: "на суму",
      SummaryService: "Сэрвісны збор",
      SummaryTotal: "Сума заказу",

      FinalThankYou: "Спасибо за покупку!",
      FinalText: "Квіткі i пацвярджэнне замовы будуць адпраўленыя на адрас электроннай пошты {email}. Вы таксама можаце спампаваць іх у дадзеным акне.",
      FinalDownloadTicket: "Спампаваць PDF білет",
      FinalDownloadInvoice: "Спампаваць рахунак",

      SelectedText: "Выбраны:",
      YouCanAddText: "Вы можаце дадаць да свайго замове:",
      TimeLeft: "Засталося часу:"
    },
    uk:{
      HeaderSelectTicket:"Вибір квитків",
      HeaderYourOrder:"Ваше замовлення",
      HeaderContact:"Контактні дані",
      HeaderFinal:"Завершення",

      ItemAvailable:"Доступно",

      BtnAddToCartBtn:"Додати",
      BtnUnavailableBtn:"Розпродано",
      BtnLookOrder:"Переглянути замовлення",
      BtnPlaceOrder:"Оформити замовлення",
      BtnPayOrder:"Оплатити замовлення",
      BtnOrderEmpty:"Кошик порожній",

      LabelSpecialPriceText:"Спец. Ціна з картою Visa від Альфа Банку",
      SpanSpecialPaymentMethod: "Оплата лише картою Visa від Альфа Банку",

      SpanContactMailtext: "Придбані Вами білети будуть відправлені на електронну адресу:",
      SpanContactMailplaceholder: "E-mail",

      CheckpointCorrect: "Підтверджую правильність моїх даних.",
      CheckpointAgree: "Я згоден з правилами користування сервісом.",

      SummaryTicket: "білет",
      Summary2Tickets: "білети",
      SummaryTickets: "білетів",
      SummarySum: "на суму",
      SummaryService: "Сервісний збір",
      SummaryTotal: "Сума замовлення",

      FinalThankYou: "Дякую за покупку!",
      FinalText: 'Білети і підтвердження замовлення будуть відправлені на адресу електронної пошти {email}. Ви також можете їх завантажити в цьому вікні.',
      FinalDownloadTicket: "Завантажити білети",
      FinalDownloadInvoice: "Завантажити рахунок",

      SelectedText: "Обрано:",
      YouCanAddText: "Ви можете додати до свого замовлення:",
      TimeLeft: "Залишилося часу:"
    },
    de:{
      HeaderSelectTicket:"Auswahl der Tickets",
      HeaderYourOrder:"Ihre Bestellung",
      HeaderContact:"Kontaktdaten",
      HeaderFinal:"Abschluss", 

      ItemAvailable:"Verfügbar",

      BtnAddToCartBtn:"Wählen",
      BtnUnavailableBtn:"Vergriffen",

      BtnLookOrder:"Bestellung anzeigen",
      BtnPlaceOrder:"Bestellung aufgeben",
      BtnPayOrder:"Für die Bestellung bezahlen",
      BtnOrderEmpty:"Der Papierkorb ist leer",

      LabelSpecialPriceText:"Sonderpreis für die Visa-Karte von der Alfa Bank",
      SpanSpecialPaymentMethod: "Zahlung nur mit Visa-Karte von der Alfa Bank",

      SpanContactMailtext: "Die von Ihnen gekauften Tickets werden an die E-Mail-Adresse gesendet:",
      SpanContactMailplaceholder: "E-mail",

      CheckpointCorrect: "Ich bestätige die Richtigkeit der von mir eingegebenen Daten.",
      CheckpointAgree: "Ich stimme den Nutzungsbedingungen des Dienstes zu.",

      SummaryTicket: "Ticket",
      Summary2Tickets: "Tickets",
      SummaryTickets: "Tickets",
      SummarySum: "im Wert",
      SummaryService: "Servicegebühr",
      SummaryTotal: "Bestellmenge",

      FinalThankYou: "Danke für den Kauf!",
      FinalText: 'Die Tickets und die Auftragsbestätigung werden an die E-Mail-Adresse gesendet {mail}. Sie können sie auch in diesem Fenster herunterladen.',
      FinalDownloadTicket: "Tickets herunterladen",
      FinalDownloadInvoice: "Konto herunterladen",

      SelectedText: "Aktiviert:",
      YouCanAddText: "Sie können zu Ihrer Bestellung hinzufügen:",
      TimeLeft: "Es bleibt noch Zeit:"
    },
    lv:{
      HeaderSelectTicket:"Biļešu izvēle",
      HeaderYourOrder:"Jūsu pasūtījums",
      HeaderContact:"Kontaktinformācija",
      HeaderFinal:"Pabeigt",

      ItemAvailable:"Pieejams",

      BtnAddToCartBtn:"Pievienot",
      BtnUnavailableBtn:"Izpārdotas",
      BtnLookOrder:"Skatīt pasūtījumu",
      BtnPlaceOrder:"Noformēt pasūtījumu",
      BtnPayOrder:"Apmaksāt pasūtījumu",
      BtnOrderEmpty:"Grozs ir tukšs",

      LabelSpecialPriceText:"Īpašā Visa kartes cena no Alfa Bankas",
      SpanSpecialPaymentMethod: "Maksājums tikai ar Visa karti no Alfa Bankas",

      SpanContactMailtext: "Iegādātās biļetes tiks nosūtītas uz e-pasta adresi:",
      SpanContactMailplaceholder: "E-mail",

      CheckpointCorrect: "Apstiprinu ievadīto datu pareizību.",
      CheckpointAgree: "Piekrītu pakalpojuma lietošanas noteikumiem.",

      SummaryTicket: "biļete",
      Summary2Tickets: "biļetes",
      SummaryTickets: "biļetes",
      SummarySum: "par",
      SummaryService: "Pakalpojumu maksa",
      SummaryTotal: "Pasūtījuma summa",

      FinalThankYou: "Paldies par pirkumu!",
      FinalText: 'Biļetes un pasūtījuma apstiprinājums tiks nosūtīti uz e-pasta adresi {email}. Tos var lejupielādēt arī šajā logā.',
      FinalDownloadTicket: "Lejupielādēt biļetes",
      FinalDownloadInvoice: "Lejupielādēt rēķinu",

      SelectedText: "Izvēlēts",
      YouCanAddText: "Jūs varat pievienot savam pasūtījumam:",
      TimeLeft: "Atlicis laiks:"
    },
    lt:{
      HeaderSelectTicket:"Bilietų pasirinkimas",
      HeaderYourOrder:"Jūsų užsakymas",
      HeaderContact:"Kontaktinė informacija",
      HeaderFinal:"Užbaigimas",

      ItemAvailable:"Galima įsigyti iš",

      BtnAddToCartBtn:"Pridėti",
      BtnUnavailableBtn:"Išparduota",
      BtnLookOrder:"Peržiūrėti užsakymą",
      BtnPlaceOrder:"Pereiti prie apmokėjimo",
      BtnPayOrder:"Pereiti prie užsakymo",
      BtnOrderEmpty:"Pirkinių krepšelis tuščias",

      LabelSpecialPriceText:"Speciali Alfa Bank Visa kaina",
      SpanSpecialPaymentMethod: "Mokėkite tik su Visa iš Alfa Bank",

      SpanContactMailtext: "Jūsų įsigyti bilietai bus išsiųsti el. paštu:",
      SpanContactMailplaceholder: "E-mail",

      CheckpointCorrect: "Įsitikinkite, kad teisingai įvedėte savo el. pašto adresą.",
      CheckpointAgree: "Sutinku su naudojimo sąlygomis.",

      SummaryTicket: "bilietas",
      Summary2Tickets: "bilietai",
      SummaryTickets: "bilietai",
      SummarySum: "penki",
      SummaryService: "Paslaugų mokestis",
      SummaryTotal: "Užsakymo vertė",

      FinalThankYou: "Dėkojame už pirkinį!",
      FinalText: 'Bilietai ir užsakymo patvirtinimas bus išsiųsti jūsų el. pašto adresu {email}. Jei negausite el. laiško per 15 minučių, susisiekite su mumis',
      FinalDownloadTicket: "Atsisiųsti bilietą PDF",
      FinalDownloadInvoice: "Atsisiųsti sąskaitą faktūrą",

      SelectedText: "Pasirinkta:",
      YouCanAddText: "Galite pridėti prie užsakymo:",
      TimeLeft: "Liko laiko:"
    },
});

var lang = navigator.language;
Localized.setLanguage('ru');
// if(lang === 'pl' || lang === 'en' || lang === 'be' || lang === 'uk' || lang === 'de' || lang === 'lv' || lang === 'lt') {
//   Localized.setLanguage(lang);
// }

export default Localized;