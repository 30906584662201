import React, { useState } from "react";
import "./style.scss";
import alfaCard from "../../../img/paymentStep/alfaCard.svg";
import simpleCard from "../../../img/paymentStep/simpleCard1.svg";
import PaymentMethodItem from "./PaymentMethodItem";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { selectPaymentMethod } from "../../../redux/slices/orderSlice";

const PaymentMethods = () => {

    const dispatch = useAppDispatch();
    const { stepCurrent } = useAppSelector((state: RootState) => state.windowSlice);
    const { paymentMethods, selectedPaymentMethod } = useAppSelector((state: RootState) => state.orderSlice);
    const [selectedMethod, setSelectedMethod] = useState(null);

    //paymentMethods

    const selectMethod = (method) => {
        setSelectedMethod(method);
    };

    if(!paymentMethods) return;

    if(stepCurrent == 2) {
        return (
            <div className="payment-methods">
                <div className="payment-methods_container">
                    <div className="payment-methods_wrapper">
                        {paymentMethods.map((item, index) => (
                            <PaymentMethodItem
                                key={index}
                                cardIcon={item.icon_url}
                                title={item.title}
                                description={item.description}
                                color={item.icon_color}
                                onClick={() => dispatch(selectPaymentMethod(item))}
                                isSelected={selectedPaymentMethod && selectedPaymentMethod.id === item.id}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (<></>);
};

export default PaymentMethods;
