import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { changeStep } from "../../../redux/slices/windowSlice";
import Header from "../../Window/Header";
import './style.scss';
import paymentOk from "../../../img/paymentStep/paymentOk.svg";

const CloseSession = () => {
    
    const { stepCurrent } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    if(stepCurrent < 5) {
        return <></>;
    }

    if(stepCurrent == 6) {
        return (
            <>
                <Header />
                <div className="close-step">
                    <div className="close-step_container">
                        <div className="close-step_wrapper">
                            <div className="close-step_info">
                                <span className="close-step_info_amount">
                                    <b>Билеты распроданы</b>
                                </span>
                                <img src={paymentOk} alt="Card" />
                                <span className="close-step_info_text">
                                    Следите за новостями и обновлениями!
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Header />
            <div className="close-step">
                <div className="close-step_container">
                    <div className="close-step_wrapper">
                        <div className="close-step_info">
                            <span className="close-step_info_amount">
                                <b>Ваш сеанс завершён!</b>
                            </span>
                            <img src={paymentOk} alt="Card" />
                            <span className="close-step_info_text">
                                Для нового заказа закройте и откройте повторно данное окно.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default CloseSession;