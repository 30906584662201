// eslint-disable-next-line
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { api } from '../api';
import { OrderData } from '../types/order';
import { changeStep, openInputEmail } from './windowSlice';


const initialState: OrderData = {
  eventId: null,
  goods: [],
  selected: [],
  maxTotal: 0,
  totalCount: 0,
  servicePrice: '',
  isHight: false,
  isEmailIncorrect: false,
  customerEmail: "",
  customerId: 0,
  paymentMethods: [],
  selectedPaymentMethod: null,
  paymentToken: "",
  paymentURL: "",
  amountTotal: ""
};

export const handlePaymentMethodsFlow = createAsyncThunk(
  'order/handlePaymentMethods',
  async (_, { dispatch, getState }) => {

    const state = getState() as RootState;
    const { customerEmail } = state.orderSlice;

    const methodId = state.orderSlice.selectedPaymentMethod['id'];
    const discountId = state.orderSlice.selectedPaymentMethod['discount_id'];
    await dispatch(api.endpoints.setPaymentMethod.initiate({ 
      payment_method: methodId,
      discount_id: discountId,
      email: customerEmail
    })).unwrap();

    dispatch(changeStep(4));
    state.windowSlice.isEmailStep = false;
  }
);

export const handleConfirmCartFlow = createAsyncThunk(
  'order/handleConfirmCart',
  async (_, { dispatch, getState }) => {

    const state = getState() as RootState;
    const { goods, eventId } = state.orderSlice;

    const cartData = {
      cart: goods.filter(item => item.count > 0).map(item => ({
          id: item.categoryId,
          count: item.count,
      })),
      event_id: eventId,
    };

    await dispatch(api.endpoints.confirmProducts.initiate(cartData)).unwrap();

    const response = await dispatch(api.endpoints.getPaymentMethods.initiate()).unwrap();
    const methods = response;

    if (methods && Object.keys(methods).length === 1) {      
      dispatch(OrderInfo.actions.selectPaymentMethod(Object(methods[0])));
      dispatch(openInputEmail(true));

    } else if (methods && Object.keys(methods).length > 1) {
      // Если два и более методов оплаты, сохраняем их в состояние
      dispatch(changeStep(2));
      // dispatch(changeStep());
      dispatch(OrderInfo.actions.setPaymentMethods(Object.values(methods)));
    }
  }
);


const OrderInfo = createSlice({
  name: 'order',
  initialState,
  reducers: {

    setEventId: (state, action) => {
      state.eventId = action.payload;
    },

    selectPaymentMethod(state, {payload}) {
      state.selectedPaymentMethod = payload;
    },

    setEmailIncorrect(state, {payload}) {
      state.isEmailIncorrect = payload;
    },

    increase(state, { payload }) {
      let canBeAdd = false;
      if(state.totalCount < state.maxTotal) {
        state.goods = state.goods.map((item) => {
          if (item.categoryId === payload) {
              if(item.maxCount > 0 && item.count < item.maxCount) { 
                  canBeAdd = true;               
                  const newCount = item.count + 1;
                  state.totalCount = state.totalCount + 1;
                  return {
                      ...item,
                      count: newCount
                  };
              }
          } 
          return item;
        });

        if (!state.selected.includes(payload) && canBeAdd) {
          state.selected.push(payload);
        }
      }
    },

    decrease(state, { payload }) {
      state.goods = state.goods.map((item) => {
        if(item.categoryId === payload) {
            const newCount = item.count - 1;
            state.totalCount = state.totalCount - 1;
            if(newCount === 0) {
              state.selected.splice(state.selected.indexOf(payload), 1);
            }
            return {
                ...item,
                count: newCount
            };
        } 
        return item;
      });
    },

    removing(state, { payload }) {
      state.goods = state.goods.map((item) => {
        if(item.categoryId === payload) {
            const newCount = 0;
            state.totalCount = state.totalCount - item.count;
            state.selected.splice(state.selected.indexOf(payload), 1);
            return {
                ...item,
                count: newCount
            };
        } 
        return item;
      });
    },

    setPaymentMethods(state, action) {
      state.paymentMethods = action.payload;
    },

    //сюда проработанные функции добавления/удаления/увеличения/уменьшения билетов в заказе + пересчет количества и общей суммый заказа + в апи сделать графу сервисного сбора отдельно;
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(
      api.endpoints.getProducts.matchFulfilled,
      (state, { payload }) => {
        if(payload.response === 'ok') {
          state.goods = payload.items;
          state.maxTotal = payload.maxTotal;
          state.servicePrice = payload.service;
          state.totalCount = payload.items.reduce((accumulator, item) => accumulator + item.count, 0);
          state.selected = payload.items.filter((item) => item.count > 0).map((item) => item.categoryId);
        } else if(payload.response === 'error') {
          window.location.replace(payload.link);
        }
      }
    )
    .addMatcher(
      api.endpoints.getMaxCountProducts.matchFulfilled,
      (state, { payload }) => {
        // Создаём карту для быстрого доступа по categoryId всех товаров из payload
        const payloadMap = new Map(payload.items.map(item => [item.categoryId, item]));
    
        // Временно сохраняем обновлённый список товаров, включая новые и обновлённые
        const updatedGoods = [];
    
        // Обновляем существующие товары и добавляем их в временный список
        state.goods.forEach(item => {
          if (payloadMap.has(item.categoryId)) {
            const updatedProduct = payloadMap.get(item.categoryId);
            updatedGoods.push({
              ...item,
              maxCount: updatedProduct.maxCount,
              freePercent: updatedProduct.freePercent,
              count: item.count > updatedProduct.maxCount ? updatedProduct.maxCount : item.count,
            });
            // После обработки удаляем товар из карты
            payloadMap.delete(item.categoryId);
          }
        });
    
        // Добавляем новые товары из payloadMap в временный список
        payloadMap.forEach((value, key) => {
          updatedGoods.push(value);
        });
    
        // Обновляем state.goods новым списком товаров
        state.goods = updatedGoods;
    
        // Удаляем из `selected` те товары, у которых maxCount стал равен 0 или которых больше нет в payload
        state.selected = state.selected.filter(selectedId =>
          state.goods.some(good => good.categoryId === selectedId && good.maxCount > 0)
        );
    
        // Пересчитываем totalCount на основе обновлённых данных в `goods` и `selected`
        state.totalCount = state.goods
          .filter(item => state.selected.includes(item.categoryId))
          .reduce((total, item) => total + item.count, 0);
      }
    )
    
    

    .addMatcher(
      api.endpoints.sendContactInfo.matchFulfilled,
      (state, { payload }) => {
        if(payload.response === 'ok') {
          window.parent.postMessage({ event: 'bePaidPayment', ...payload }, '*');
        } 
      }
    )

    .addMatcher(
      api.endpoints.updateProducts.matchFulfilled,
        (state, { payload }) => {
          if(payload['response'] === 'ok') {
            state.goods = payload.items;
            state.totalCount = payload.items.reduce((accumulator, item) => accumulator + item.count, 0);
            state.selected = payload.items.filter((item) => item.count > 0).map((item) => item.categoryId);
            state.isHight = payload.hight;
          } else {
            state.goods = [];
            state.totalCount = 0;
            state.selected = [];
            state.isHight = payload.hight;
          }
        }
    )
    .addMatcher(
      api.endpoints.confirmProducts.matchFulfilled,
        (state, { payload }) => {
          if(payload['response'] === 'ok') {
            state.goods = payload.items;
            state.totalCount = payload.items.reduce((accumulator, item) => accumulator + item.count, 0);
            state.selected = payload.items.filter((item) => item.count > 0).map((item) => item.categoryId);
            state.isHight = payload.hight;
          } else {
            state.goods = [];
            state.totalCount = 0;
            state.selected = [];
            state.isHight = payload.hight;
          }
        }
    )
    .addMatcher(
      api.endpoints.setEmail.matchFulfilled,
        (state, { payload }) => {
          state.customerEmail = payload.customer_email;
          state.customerId = payload.customer_id;
        }
    )
    .addMatcher(
      api.endpoints.setEmail.matchRejected,
      (state, action) => {
        if (action.payload) { 
          switch (action.payload.status) {
            case 422:
            case 400:
              state.isEmailIncorrect = true;
              break;
            case 429:
              state.isEmailIncorrect = true;
              break;

            default:
              console.log(`HTTP статус ошибки: ${action.payload}. Перенаправление на финальную страницу.`);
              break;
          }
        } else if (action.error) { 
          console.log(`Ошибка без payload: ${action.error}`);
        }
      }
    )
    .addMatcher(
      api.endpoints.setPaymentMethod.matchFulfilled,
        (state, { payload }) => {
          state.paymentToken = payload.token;
          state.paymentURL = payload.url;
          state.amountTotal = payload.total_price;
        }
    )
  },
});

export const selectGoods = (state: RootState) => state.orderSlice.goods;
export const { increase, decrease, removing, setEmailIncorrect, selectPaymentMethod, setEventId } = OrderInfo.actions;
export default OrderInfo.reducer;
