import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import Localized from "../../../../Localized";
import './style.scss';

const Summary = () => {

    const { stepCurrent, isEmailStep } = useAppSelector((state: RootState) => state.windowSlice);

    const { totalCount, servicePrice, selectedPaymentMethod } = useAppSelector((state: RootState) => state.orderSlice);

    const { goods, selected } = useAppSelector((state: RootState) => state.orderSlice);

    const formatNumber = (number) => 
        number.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true }).replace(/,/g, '.');

    let amount = 0;
    let service = 0;
    let totalPrice = 0;

    goods.filter((item) => selected.includes(item.categoryId))
    .sort((a, b) => selected.indexOf(a.categoryId) - selected.indexOf(b.categoryId))
    .map((item) => {
        amount = amount + Number(item.prices.standart) * item.count;
        service = service + Number(item.prices.service) * item.count;
        totalPrice = amount + service;
        return true;
    });


    const declOfNum = (number: number, titles: any[]) => {  
        let cases = [2, 0, 1, 1, 1, 2];  
        return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
    }
    const textCount = totalCount + ' ' + declOfNum(totalCount, [Localized.SummaryTicket, Localized.Summary2Tickets, Localized.SummaryTickets]);

    const discount = () => {
        if(selectedPaymentMethod) {
            if(selectedPaymentMethod.price_with_discount !== selectedPaymentMethod.price_without_discount) {
                return(
                    <div className="summary_service">
                        <span className="summary_service_text" style={{color: selectedPaymentMethod.icon_color}}>{selectedPaymentMethod.description}</span>
                        <span className="summary_service_price" style={{color: selectedPaymentMethod.icon_color}}>{formatNumber(selectedPaymentMethod.discount)} Br</span>
                    </div>
                )
            }

            return(
                <></>
            )
        }
    }

    const summaryTotal = () => {
        if(selectedPaymentMethod) {
            return (selectedPaymentMethod.price_with_discount).toFixed(2);
        }
        return (totalPrice/100).toFixed(2);
    }


    // //const summaryTotalValue = selectedPaymentMethod ? (selectedPaymentMethod.price_with_discount).toFixed(2) : (totalPrice / 100).toFixed(2);
    // const summaryTotalWithoutDiscount = selectedPaymentMethod ? (selectedPaymentMethod.price_without_discount).toFixed(2) : (totalPrice / 100).toFixed(2);

    const [currentValue, setCurrentValue] = useState(Number((totalPrice).toFixed(2)));
    const [summaryTotalValue, setTotalValue] = useState(Number((totalPrice).toFixed(2)));

    // useEffect(() => {
    //     // Обновление текущего значения для анимации
    //     setCurrentValue(Number(selectedPaymentMethod ? (selectedPaymentMethod.price_with_discount).toFixed(2) : (totalPrice / 100).toFixed(2)));
    //     setTotalValue(Number(selectedPaymentMethod ? (selectedPaymentMethod.price_without_discount).toFixed(2) : (totalPrice / 100).toFixed(2)));
    // }, [summaryTotalValue, currentValue, selectedPaymentMethod]); // Зависимость от summaryTotalValue гарантирует обновление при его изменении

    useEffect(() => {
        const isFinalPrice = (selectedPaymentMethod && stepCurrent == 2 ) || (isEmailStep && stepCurrent == 1)
        const newTotalValue = isFinalPrice ? selectedPaymentMethod.price_with_discount : totalPrice;
        const newTotalWithoutDiscount = isFinalPrice ? selectedPaymentMethod.price_without_discount : totalPrice;
    
        let tmp = currentValue;
        // Если скидочная цена меньше обычной, то используем её как конечное значение.
        // В противном случае, скидочная цена становится начальным значением.
        if (newTotalValue < newTotalWithoutDiscount) {
            setTotalValue(newTotalWithoutDiscount); // Устанавливаем обычную цену как начальное значение
            setCurrentValue(newTotalValue); // Устанавливаем скидочную цену как конечное значение
        } else {
            setTotalValue(tmp); // Устанавливаем скидочную цену как начальное значение
            setCurrentValue(newTotalValue); // Скидочная цена также является конечным значением, так как она равна обычной цене
        }
    }, [selectedPaymentMethod, totalPrice]); // Зависимости: selectedPaymentMethod и totalPrice

    return (
        <div className="summary" style={stepCurrent>0 ? {transform: 'translateY(0)', opacity: '1',  pointerEvents: 'all', maxHeight: '145px'} : {}}>
            <div className="summary_container">
                <div className="summary_wrapper">
                    <div className="summary_count">
                        <span className="summary_count_text">{textCount} {Localized.SummarySum}</span>
                        <span className="summary_count_price">{formatNumber(amount)} Br</span>
                    </div>
                    <div className="summary_service">
                        <span className="summary_service_text">{Localized.SummaryService} ({totalCount} х {(Number(servicePrice)).toFixed(2)} Br)</span>
                        <span className="summary_service_price">{formatNumber(service)} Br</span>
                    </div>
                    {discount()}
                    <div className="summary_total">
                        <span className="summary_total_text">{Localized.SummaryTotal}</span>
                        <span className="summary_total_price">
                            <CountUp 
                                key={`count-up-${summaryTotalValue}`}
                                start={summaryTotalValue} 
                                end={currentValue} 
                                duration={0.5} 
                                decimals={2} 
                                decimal="." 
                                prefix="" 
                                suffix=" Br" 
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Summary;