import React, { useState, useEffect } from "react";
import "./style.scss";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { closeInputEmail } from "../../../redux/slices/windowSlice";
import {
    handlePaymentMethodsFlow,
    setEmailIncorrect,
} from "../../../redux/slices/orderSlice";
import { useSetEmailMutation } from "../../../redux/api";

const EmailModal = () => {
    const dispatch = useAppDispatch();
    const { isEmailStep } = useAppSelector(
        (state: RootState) => state.windowSlice
    );
    const { isEmailIncorrect, selectedPaymentMethod } = useAppSelector(
        (state: RootState) => state.orderSlice
    );
    const [setEmail] = useSetEmailMutation();
    const [emailValue, setEmailValue] = useState("");
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [emailLetter, setEmailLetter] = useState([]);
    const [showLetters, setShowLetters] = useState(false);
    const [pulseAnimation, setPulseAnimation] = useState(false);
    const [shakeAnimation, setShakeAnimation] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // состояние для отображения лоадера
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        if (isEmailIncorrect) {
            setShakeAnimation(true);
            setTimeout(() => {
                setShakeAnimation(false);
                setIsButtonDisabled(true); // Кнопка становится негативной после ошибки
            }, 500); // Сброс анимации через 0.5 секунды
        }
    }, [isEmailIncorrect]);

    if (!selectedPaymentMethod) {
        return null;
    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleBackStep = () => {
        if (!showLetters) {
            dispatch(closeInputEmail(false));
            dispatch(setEmailIncorrect(false));
            setEmailValue("");
            setIsEmailEmpty(false);
        } else {
            setShowLetters(false);
        }
    };

    const handleInputChange = (event) => {
        dispatch(setEmailIncorrect(false));
        const newEmailValue = event.target.value;
        setEmailValue(newEmailValue);
        setIsEmailEmpty(!isValidEmail(newEmailValue));
        setEmailLetter(newEmailValue.split(""));
        setIsButtonDisabled(false);
    };

    const handleSendEmail = async () => {
        if (!isEmailEmpty && isValidEmail(emailValue)) {
            const emailData = {
                email: emailValue,
            };

            try {
                if (showLetters) {
                    setIsLoading(true); // Показать лоадер
                    await setEmail(emailData).unwrap();
                    setIsLoading(false); // Скрыть лоадер

                    if (isEmailIncorrect) {
                        setIsButtonDisabled(true);
                        setShakeAnimation(true); // Запуск анимации тряски
                        setTimeout(() => {
                            setShakeAnimation(false);
                             // Кнопка становится негативной после ошибки
                        }, 500); // Остановка анимации через 0.5 секунды
                    } else {
                        dispatch(handlePaymentMethodsFlow());
                    }
                } else {
                    setPulseAnimation(true);
                    setShowLetters(true);
                }
            } catch (error) {
                console.error("Ошибка при выполнении запроса:", error);
                setIsLoading(false); // Скрыть лоадер в случае ошибки
            }
        } else {
            setIsEmailEmpty(true);
        }
    };

    const colorBtn = selectedPaymentMethod.icon_color;

    return isEmailStep ? (
        <div className="email-modal">
            <div className={`email-modal_wrapper ${pulseAnimation ? '' : ''}`} onAnimationEnd={() => setPulseAnimation(false)}>
                {!showLetters ? (
                    <span className="email-modal_text">
                        Введите адрес электронной почты, на который будут
                        отправлены билеты
                    </span>
                ) : null}

                <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    className={`email-modal_input ${
                        showLetters ? "hidden" : ""
                    }`}
                    error={isEmailIncorrect || isEmailEmpty}
                    value={emailValue}
                    onChange={handleInputChange}
                    type="email"
                    helperText={
                        (isEmailIncorrect || isEmailEmpty) &&
                        "Введите корректный email."
                    }
                />

                <div
                    className={`email-modal_letters ${
                        showLetters ? `visible pulse ${shakeAnimation ? 'shake' : ''}` : ""
                    }`}>
                    {emailLetter.map((item, index) => (
                        <span
                            key={index}
                            className="email-modal_letters_item">
                            {item}
                        </span>
                    ))}
                </div>

                {showLetters ? (
                    <span className="email-modal_text">
                        <b>Важно!</b><br/>
                        Пожалуйста, убедитесь, что адрес электронной почты
                        введён правильно.
                    </span>
                ) : null}

                <div className="email-modal_btns">
                    <div
                        className="email-modal_cancel"
                        onClick={handleBackStep}>
                        {showLetters ? "Изменить" : "Назад"}
                    </div>
                    <div
                        className="email-modal_submit"
                        style={{ background: isButtonDisabled ? '#ccc' : colorBtn }}
                        onClick={!isButtonDisabled && !isLoading ? handleSendEmail : null}
                    >
                        {isLoading ? (
                            <div className="loader"></div>
                        ) : (
                            showLetters && !isEmailIncorrect ? (
                                "Всё верно :)"
                            ) : "Продолжить"
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default EmailModal;
