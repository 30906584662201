import { useMemo, useState } from "react";
import Localized from "../../../../Localized";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import ButtonAdd from "./ButtonAdd";
import './style.scss';
import { useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

const getColor = (num: string | number) => {
    if(num >= 0 && num < 35) {
        return '#F03226';
    } else if(num>=35 && num < 70) {
        return '#FF9C00';
    } else {
        return '#43DF91';
    }
};

const Item = ({ product, isSelected }) => {
    const { categoryId, pathColor, pathPhoto, title, count, maxCount, prices, freePercent } = product;
    const { standart } = prices;

    const { totalCount, maxTotal } = useAppSelector((state: RootState) => state.orderSlice);
    const dispatch = useAppDispatch();

    let changePriceStandart: number;

    if(count===0) {
        changePriceStandart = standart * 1;
    } else {
        changePriceStandart = standart * count;
    }

    const changePriceStandartString = changePriceStandart.toFixed(2); // Преобразование в строку с 2 десятичными знаками
    const [wholePart, fractionalPart] = changePriceStandartString.split('.'); // Разделение на целую и дробную части
    
    return(
        <div className="ticket" style={isSelected ? {boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'} : {}}>
            <div className="ticket_wrapper">
                <div className="ticket_color" style={{background: `${pathColor}`}}></div>
                <div className="ticket_photo" style={{background: `url(${pathPhoto}), ${pathColor}`, backgroundSize: 'cover', backgroundPosition: "center" }}></div>
                <div className="ticket_information">
                    <h1 className="ticket_information_title">{title}</h1>
                    <div className="ticket_information_left">
                        <span className="ticket_information_left_text">{Localized.ItemAvailable}</span>
                        <div className="ticket_information_left_indicator">
                            <div className="ticket_information_left_indicator_actual" style={{width: freePercent > 10 ? (freePercent + '%') : '10%', background: getColor(freePercent)}}></div>
                            <div className="ticket_information_left_indicator_all"></div>
                        </div>
                    </div>
                </div>
                <div className="ticket_right">
                    <div className="ticket_price">
                        <div className="ticket_price_actual">
                            <span className="ticket_price_actual_whole">{wholePart}</span>
                            <span className="ticket_price_actual_fractional">{fractionalPart}</span>
                            <span className="ticket_price_actual_currency">Br</span>
                        </div>
                    </div>
                    <ButtonAdd 
                        id={categoryId} 
                        count={count}
                        maxCount={maxCount}
                        totalCount={totalCount}
                        maxTotal={maxTotal}
                    />
                </div>
            </div>
        </div>
    );
}

export default Item;