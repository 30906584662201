import ButtonFooter from "./ButtonFooter";
import Summary from "./Summary";
import './style.scss';
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

const Footer = () => {

    const { stepCurrent } = useAppSelector((state: RootState) => state.windowSlice);
    const { totalCount } = useAppSelector((state: RootState) => state.orderSlice);

    const getButton = () => {
        switch (stepCurrent) {
            case 0:
            case 1:
            case 2: 
            case 3: 
                return (
                    <ButtonFooter />
                );
        }
    }
    const getSummary = () => {
        switch (stepCurrent) {
            case 0:
            case 1:
            case 2: 
            case 3: 
                return (
                    <Summary />
                );
        }
    }
    return (
        <div className="footer" style={totalCount === 0 ? {pointerEvents: 'none'} : {}}>
            {getSummary()}
            {getButton()}
        </div>
    );
}

export default Footer;