import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import Localized from '../../../Localized';
import { useCloseSessionMutation } from '../../../redux/api';
import { changeStep } from "../../../redux/slices/windowSlice";

interface CountdownState {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const Timer = () => {
    const { expirationTime } = useAppSelector((state: RootState) => state.userSlice);
    const [tick, setTick] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const dispatch = useAppDispatch();
    const [ closeSession ] = useCloseSessionMutation();

    const [diff, setDiff] = useState<CountdownState>({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    
      useEffect(() => {
        if (!expirationTime || isExpired) return;

        // Преобразование строки в объект Date для использования метода getTime()
        const expirationDate = new Date(expirationTime);
        const remainingTime = (expirationDate.getTime() - new Date().getTime()) / 1000;

        if (remainingTime < 0) {
            dispatch(changeStep(5));
            closeSession();
            setIsExpired(true);
            return;
        }; 
    
        setDiff({
          days: Math.floor(remainingTime / 86400),
          hours: Math.floor((remainingTime / 3600) % 24),
          minutes: Math.floor((remainingTime / 60) % 60),
          seconds: Math.floor(remainingTime % 60),
        });
    
      }, [expirationTime, tick, isExpired]);

        
    useEffect(()=>{
        const timerID = setInterval(() => {
            setTick(!tick);
        }, 1000);
        return () => clearInterval(timerID);
    }, [tick]);

   
    if(expirationTime && !isExpired) {
        
        const minutes = diff.minutes.toString().padStart(2, '0');
        const seconds = diff.seconds.toString().padStart(2, '0');

        return (
            <span className='header_order-time'><b>{`${minutes}:${seconds}`}</b> {Localized.TimeLeft} </span>
        )
    } else {
        return null;
    }
   
}

export default Timer;