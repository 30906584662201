import "./style.scss";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

const WaitingStep = () => {
    const { stepCurrent, globalMsg } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    if (stepCurrent != -1) {
        return <></>;
    }

    if(globalMsg) {
        return (
            <>
                <div className="waiting-step">
                    <div className="waiting-step_container">
                        <div className="waiting-step_wrapper">
                            <div className="waiting-step_info">
                                {/* <span className="waiting-step_info_amount">
                                    <b>Приносим извинения!</b>
                                </span> */}
                                <span className="waiting-step_info_text">
                                    {globalMsg}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="waiting-step">
                <div className="waiting-step_container">
                    <div className="waiting-step_wrapper">
                        <div className="waiting-step_info">
                            <span className="waiting-step_info_text">
                                <b>Внимание!</b>
                            </span>
                            <span className="waiting-step_info_text">
                                На вашем соединении уже зарегистрирована
                                активная сессия.
                            </span>
                            <span className="waiting-step_info_text">
                                Пожалуйста, закройте все вкладки сайта,
                                отключите VPN и/или перейдите на личную сеть.
                                Повторите попытку через несколько минут.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WaitingStep;
